// 1. get throne data api
export const GET_THRONE = "https://assignments.reaktor.com/birdnest/drones";

// 2. get pilot data
export const GET_PILOT = "https://assignments.reaktor.com/birdnest/pilots/";

// 3. Proxy url
export const CORS_PROXY_URL = "https://cors-anywhere.herokuapp.com/";


